import React from "react"
import styled from "styled-components"

import Layout from "../layout"
import { Message } from "../components/l10n"
import { Content, Title } from "../components/styled"

const constants = {
  name: "Isabel Bibriesca Mejia",
  brand: "SlowPrint",
  mail: "isabel@slowprint.fr",
  siret: "851 653 600 00011",
}

const Subtitle = styled.h2`
  font-size: 22px;
`
const Contact = styled.p`
  margin-bottom: 50px;
`

const LegalPage = () => {
  return (
    <Layout noindex legal>
      <Content>
        <Title>
          <Message id="contact" />
        </Title>
        <Contact>
          <Message id="contact_text" /> <a href={`mailto:${constants.mail}`}>{constants.mail}</a>
        </Contact>
        <Title>Mentions légales</Title>
        <p>
          Mentions légales réglementées par la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
          aux libertés et la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.
        </p>
        <Subtitle>Confidentialite des données personnelles</Subtitle>
        <p>
          Vos données personnelles (nom, prénom, adresse, code postal, ville, e-mail, téléphone) sont collectées dans le
          strict respect de la loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique.
        </p>
        <p>
          Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés
          modifiée par la nouvelle loi Informatique et Libertés du 6 août 2004, vous disposez d’un droit d’accès, de
          modifications, de rectifications et de suppression des données qui vous concernent. Pour cela il vous suffit
          de nous contacter par courrier, par e-mail ou par téléphone.
        </p>
        <Subtitle>Nos coordonnées</Subtitle>
        <p>
          {constants.name}
          <br />
          2 impasse Domaine Vaissette
          <br />
          31000 Toulouse
        </p>
        <p>
          Téléphone : <a href={"tel:+33769488243"}>07 69 48 82 43</a>
        </p>
        <p>
          E-mail : <a href={`mailto:${constants.mail}`}>{constants.mail}</a>
        </p>
        <p>SIRET : {constants.siret}</p>
        <Subtitle>Editeurs du site</Subtitle>
        <p>
          Développement : <a href="https://antoine.rousseau.im">Antoine Rousseau</a>
        </p>
        <p>
          Graphisme : <a href="https://isabel.bibriesca.net">Isabel Bibriesca</a>
        </p>
        <p>
          Hébergement : <a href="https://www.netlify.com">Netlify</a> et{" "}
          <a href="https://www.contentful.com">Contentful</a>
        </p>
        <p>
          Paiement : <a href="https://stripe.com/fr">Stripe</a>
        </p>
      </Content>
      <Content>
        <Title>Conditions générales de vente</Title>
        <p>
          Les présentes conditions de vente sont conclues entre {constants.name}, micro entreprise, SIRET{" "}
          {constants.siret}, via l’enseigne &laquo; {constants.brand} &raquo;, et par toute personne physique ou morale
          souhaitant procéder à un achat via ce site Internet, dénommée ci-après &laquo; l’acheteur &raquo;.
        </p>
        <h3>Objet</h3>
        <p>
          Les présentes conditions de vente visent à définir les relations contractuelles entre {constants.name} et
          l’acheteur et les conditions applicables à tout achat effectué par le biais du site marchand de{" "}
          {constants.name}, que l’acheteur soit professionnel ou consommateur.
        </p>
        <p>
          L’acquisition d’un produit à travers le présent site implique une acceptation sans réserve par l’acheteur des
          présentes conditions de vente. Ces conditions de vente prévaudront sur toutes autres conditions générales ou
          particulières non expressément agréées par {constants.name}.
        </p>
        <p>
          {constants.name} se réserve de pouvoir modifier ses conditions de vente à tout moment. Dans ce cas, les
          conditions applicables seront celles en vigueur à la date de la commande par l’acheteur.
        </p>
        <h3>Caractéristiques des produits proposés</h3>
        <p>
          Les produits offerts sont ceux qui figurent dans le catalogue publié sur ce site. Ces produits sont offerts
          dans la limite des stocks disponibles.
        </p>
        <p>
          Les photographies du catalogue sont les plus fidèles possibles mais ne peuvent assurer une similitude parfaite
          avec le produit offert, notamment du fait du caractère artisanal des créations.
        </p>
        <h3>Tarifs</h3>
        <p>Les prix figurant dans le catalogue sont des prix TTC en euros. TVA non applicable, article 293 B du CGI.</p>
        <p>
          {constants.name} se réserve de modifier ses prix à tout moment, étant toutefois entendu que le prix figurant
          au catalogue le jour de la commande sera le seul applicable à l’acheteur.
        </p>
        <p>
          Les prix indiqués sur chaque produit ne comprennent pas les frais de traitement de commandes, de transport et
          de livraison, qui sont ajoutés au moment de la commande.
        </p>
        <p>
          La livraison s’effectue via La Poste ou tout autre transporteur choisi par {constants.name} au moment de
          l’envoi, à l’adresse choisie et indiquée par l’acheteur au moment de la commande.
        </p>
        <h3>Taxes</h3>
        <p>
          Les livraisons à destination ou en provenance de pays situés hors de l’Union Européenne peuvent être soumise à
          divers frais et impôts, notamment la douane et la TVA à l’importation. Ces frais restent à la charge de
          l’acheteur.
        </p>
        <h3>Commandes</h3>
        <p>
          L’acheteur, qui souhaite acheter une prestation, doit obligatoirement : remplir la fiche d’identification sur
          laquelle il indiquera toutes les coordonnées demandées ; remplir le bon de commande en ligne en donnant toutes
          les informations demandées ; valider sa commande après l’avoir vérifiée ; effectuer le paiement dans les
          conditions prévues.
        </p>
        <p>
          La confirmation de la commande entraîne acceptation des présentes conditions de vente, la reconnaissance d’en
          avoir parfaite connaissance et la renonciation à se prévaloir de ses propres conditions d’achat ou d’autres
          conditions.
        </p>
        <p>
          L’ensemble des données fournies et la confirmation enregistrée vaudront preuve de la transaction. La
          confirmation vaudra signature et acceptation des opérations effectuées.
        </p>
        <p>Le vendeur communiquera par courrier électronique confirmation de la commande enregistrée.</p>
        <h3>Modalités de paiement</h3>
        <p>Le montant total de la commande, frais de livraison inclus, est exigible à la commande.</p>
        <p>
          Les paiements seront effectués à la commande par carte bancaire; ils seront réalisés par le biais du système
          sécurisé Stripe qui utilise le protocole SSL de telle sorte que les informations transmises sont cryptées par
          un logiciel et qu’aucun tiers ne peut en prendre connaissance au cours du transport sur le réseau.
          <br />
          Le formulaire de paiement étant hébergé par Stripe.com, les informations de paiement sont directement
          transmises de l’acheteur à Stripe et ne sont donc pas accessible par {constants.name} ni même par son
          hébergeur.
          <br />
          Les conditions générales Stripe prévalent en ce qui concerne les échanges de flux financiers.
        </p>
        <p>
          Le compte de l’acheteur ne sera débité que du montant des produits envoyés ainsi que des frais de livraison.
        </p>
        <p>À la demande de l’acheteur, il lui sera adressée une facture.</p>
        <p>Les frais de livraison dépendent du pays de destination et sont indiqués sur le bon de commande en ligne.</p>
        <h3>Rétractation</h3>
        <p>
          D’après la loi du 14 juillet 1991, modifiée par la loi du 06 avril 2010, l’acheteur a le droit de notifier au
          vendeur qu'il renonce à l'achat, sans pénalités et sans indication de motif, dans les 15 jours ouvrables à
          dater du lendemain du jour de la livraison. Si le délai expire un samedi, dimanche ou un jour férié ou chômé,
          il est prorogé jusqu’au premier jour ouvrable suivant.
        </p>
        <p>
          Conformément à l’article 80 § 4 de la loi sur les pratiques du commerce, le droit de rétractation ne peut être
          exercé pour les créations qui auraient fait l’objet d’une personnalisation, ou qui auraient été confectionnées
          selon les demandes de l’acheteur (sur mesure).
        </p>
        <p>
          Le retour en cas de rétractation ne pourra être accepté que si la création est en parfait état, à savoir non
          endommagée, dans son emballage d’origine et accompagnée de tous ses accessoires, la création retournée devant
          être propre à une nouvelle commercialisation. Les retours de créations endommagées, utilisées ou salies par
          l’acheteur, ou renvoyées sans l’ensemble de leurs accessoires, seront refusés.
        </p>
        <p>
          En cas d’exercice du droit de rétractation avéré (les conditions de retour des créations étant remplies),{" "}
          {constants.name} remboursera à l’acheteur la totalité des sommes versées, incluant les frais de retour, sur
          présentation de justificatif de paiement, dans un délai de 30 (trente) jours suivant la date à laquelle ce
          droit a été exercé, par re-crédit de sa carte bancaire.
        </p>
        <p>
          Si les conditions de retour des créations ne sont pas remplies, le colis sera renvoyé à l’acheteur, tous les
          frais de transport restant à sa charge.
        </p>
        <h3>Archivage - Preuve</h3>
        <p>
          {constants.name} archivera les bons de commandes et les factures sur un support fiable et durable constituant
          une copie fidèle conformément aux dispositions de l’article 1348 du Code civil.
          <br />
          Les registres informatisés de {constants.name} seront considérés par les parties comme preuve des
          communications, commandes, paiements et transactions intervenus entre les parties.
        </p>
        <h3>Propriété intellectuelle</h3>
        <p>
          Tous les éléments du site de {constants.name} sont et restent la propriété intellectuelle et exclusive de{" "}
          {constants.name}. Personne n’est autorisé à reproduire, exploiter, rediffuser, ou utiliser à quelque titre que
          ce soit, même partiellement, des éléments du site qu’ils soient logiciels, visuels ou sonores.
        </p>
        <h3>Règlement des litiges</h3>
        <p>
          Les présentes conditions de vente en ligne sont soumises à la loi française. En cas de litige, compétence est
          attribuée aux tribunaux compétents, nonobstant pluralité de défendeurs ou appel en garantie.
        </p>
      </Content>
    </Layout>
  )
}

export default LegalPage
